import React from "react"
import Store from "../../content/store"
import LayoutProduct from "../../components/shared/layout/layout-product"
import {useIntl} from "gatsby-plugin-intl";

const id = "luxdecor-impregnating-agent";

const LuxdecorImpregnatingAgent = () => {
    const intl = useIntl()
    const data = {
        title: intl.formatMessage({id: `seo.${id}.title`}),
        description: intl.formatMessage({id: `seo.${id}.desc`}),
    };
  return (
    <LayoutProduct seo={data} product={Store["impregnating-agents"].find(item => item.id === id)}/>
  )
};

export default LuxdecorImpregnatingAgent
